<template>
  <b-form-group
    :description="formattedDuration"
    label="Estimated Program Duration"
    label-for="duration-input"
  >
    <b-input-group>
      <b-form-input
        id="duration-input"
        v-model="durationValue"
        min="1"
        type="number"
        aria-label="Duration value"
        @input="updateDuration"
      />
      <b-form-select
        v-model="durationTime"
        :options="timeOptions"
        aria-label="Duration time"
        @change="updateDuration"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BInputGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'

export default {
  name: 'DurationInput',
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormSelect,
  },
  props: {
    value: {
      type: String,
      default: '1 day',
    },
  },
  data() {
    return {
      durationValue: 1,
      durationTime: 'days',
      timeOptions: [
        { value: 'days', text: 'Days' },
        { value: 'months', text: 'Months' },
      ],
    }
  },
  computed: {
    formattedDuration() {
      return `${this.durationValue} ${this.durationTime}`
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.parseDuration(newValue)
      },
    },
    durationValue(newValue) {
      if (newValue < 1) {
        this.durationValue = 1
      }
    },
  },
  methods: {
    parseDuration(durationString) {
      const [value, time] = durationString.split(' ')
      this.durationValue = parseInt(value, 10)
      this.durationTime = time
    },
    updateDuration() {
      this.$emit('input', `${this.durationValue} ${this.durationTime}`)
    },
  },
}
</script>
